<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.scores") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="close()">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- ****************   Start Molad-content  Modal **************** -->

    <div class="modal-body mt-3">
      <el-form
        ref="form"
        status-icon
        :model="form"
        :rules="rules"
        :label-position="'left'"
      >
        <el-row :gutter="20">
          <el-col :sm="12">
            <el-form-item prop="name" label="Наименование">
              <el-input v-model="form.name" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="12">
            <el-form-item label="МФО">
              <el-input v-model="form.mfo" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="12">
            <el-form-item label="Номер">
              <el-input v-model="form.number" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="12">
            <el-form-item prop="name" label="Баланс">
              <el-input v-model="form.balance" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="12">
            <el-form-item label="Валюта">
              <currencies
                v-model="form.currency_id"
                :currency_id="form.currency_id"
              ></currencies>
              <!-- <el-select style="width: 100%" v-model="form.region">
                <el-option label="Партнер клиника" value="beijing"></el-option>
                <el-option label="Партнер доктор" value="shanghai3"></el-option>
                <el-option label="% Наши Доктор " value="shanghai2"></el-option>
                <el-option
                  label="Процессы Л.П.Д "
                  value="shanghai1"
                ></el-option>
              </el-select> -->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import currencies from "@/components/inventory-select/currencies";
import drawerChild from "@/utils/mixins/drawer-child";
export default {
  components: { currencies },
  mixins: [form, drawerChild],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      scores: "scores/list",
      model: "scores/model",
      rules: "scores/rules",
      columns: "scores/columns",
      currencies: "currencies/list",
    }),
  },
  async mounted() {
    if (this.categories && this.categories.length === 0)
      await this.loadCategories();
    if (this.services && this.services.length === 0) await this.loadServices();

    if (this.currencies && this.currencies.length === 0)
      await this.loadCurrencies();
  },
  methods: {
    ...mapActions({
      save: "scores/store",
      loadCurrencies: "currencies/index",
      loadScores: "scores/index",
      colorScores: "scores/color",
      loadColor: "scores/show",
    }),
    afterOpened() {
      this.form = JSON.parse(JSON.stringify(this.model));
    },
    afterClosed() {
      this.empty();
    },
    submit(resetForm = false) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;

          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
              this.parent().listChanged();

              this.close();
            })
            .catch((err) => {
              this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
              this.loadingButton = false;
            })
            .finally(() => {
              this.$refs["form"].resetFields();
              this.empty();
              this.parent().listChanged();

              this.close();
            });
        }
      });
    },
  },
};
</script>
